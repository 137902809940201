var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <img alt=\"Route\" class=\"responsive map\" src=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"trip") : depth0)) != null ? lookupProperty(stack1,"routeMapURL") : stack1), depth0))
    + "\"/>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"row detail-row\">\n                        <div class=\"col-xs-4 label\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(alias1,"tpl.page.booking-page.layout.travel",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":44,"column":52},"end":{"line":44,"column":111}}})) != null ? stack1 : "")
    + ":</div>\n                        <div class=\"col-xs-8\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"travel") : depth0)) != null ? lookupProperty(stack1,"headline") : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":28},"end":{"line":48,"column":35}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"travel") : depth0)) != null ? lookupProperty(stack1,"details") : stack1),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":28},"end":{"line":71,"column":37}}})) != null ? stack1 : "")
    + "                        </div>\n                    </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div> "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"travel") : depth0)) != null ? lookupProperty(stack1,"headline") : stack1), depth0))
    + "</div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div>"
    + alias4(((helper = (helper = lookupProperty(helpers,"headline") || (depth0 != null ? lookupProperty(depth0,"headline") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"headline","hash":{},"data":data,"loc":{"start":{"line":50,"column":37},"end":{"line":50,"column":51}}}) : helper)))
    + "</div>\n                                <div>\n                                    "
    + alias4(((helper = (helper = lookupProperty(helpers,"details") || (depth0 != null ? lookupProperty(depth0,"details") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"details","hash":{},"data":data,"loc":{"start":{"line":52,"column":36},"end":{"line":52,"column":49}}}) : helper)))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isOwn") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":36},"end":{"line":56,"column":47}}})) != null ? stack1 : "")
    + "                                </div>\n                                <div>\n                                    "
    + alias4(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":59,"column":36},"end":{"line":59,"column":47}}}) : helper)))
    + "\n                                </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasAdditionalDetails") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":32},"end":{"line":65,"column":39}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(data && lookupProperty(data,"first")),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":32},"end":{"line":70,"column":39}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        "
    + container.escapeExpression((lookupProperty(helpers,"formatDateString")||(depth0 && lookupProperty(depth0,"formatDateString"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"departureDate") : depth0),"longDate",{"name":"formatDateString","hash":{},"data":data,"loc":{"start":{"line":54,"column":40},"end":{"line":54,"column":86}}}))
    + "\n                                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"departureTime") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":40},"end":{"line":55,"column":154}}})) != null ? stack1 : "")
    + "\n";
},"8":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " / "
    + alias3((lookupProperty(helpers,"formatDateString")||(depth0 && lookupProperty(depth0,"formatDateString"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"departureTime") : depth0),"shortTime",{"name":"formatDateString","hash":{},"data":data,"loc":{"start":{"line":55,"column":64},"end":{"line":55,"column":111}}}))
    + " "
    + alias3((lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"general.clock",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":55,"column":112},"end":{"line":55,"column":147}}}));
},"10":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <div>\n                                        "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"additionalDetails") || (depth0 != null ? lookupProperty(depth0,"additionalDetails") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"additionalDetails","hash":{},"data":data,"loc":{"start":{"line":63,"column":40},"end":{"line":63,"column":62}}}) : helper)))
    + "\n                                    </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isOwn") : depth0),{"name":"unless","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":36},"end":{"line":69,"column":47}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    return "                                        <div class=\"spacer\"></div>\n";
},"15":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"insurance") : depth0)) != null ? lookupProperty(stack1,"display") : stack1),{"name":"if","hash":{},"fn":container.program(16, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":81,"column":20},"end":{"line":96,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isB2B") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":97,"column":20},"end":{"line":108,"column":27}}})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"row detail-row\">\n                            <div class=\"col-xs-4 label\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(alias1,"general.insurance.travel",{"name":"formatMessage","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":83,"column":56},"end":{"line":83,"column":104}}})) != null ? stack1 : "")
    + ":</div>\n                            <div class=\"col-xs-8\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"insurance") : depth0)) != null ? lookupProperty(stack1,"items") : stack1),{"name":"each","hash":{},"fn":container.program(17, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":85,"column":32},"end":{"line":93,"column":41}}})) != null ? stack1 : "")
    + "                            </div>\n                        </div>\n";
},"17":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <div>"
    + alias4(((helper = (helper = lookupProperty(helpers,"insurance") || (depth0 != null ? lookupProperty(depth0,"insurance") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"insurance","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":86,"column":41},"end":{"line":86,"column":56}}}) : helper)))
    + ", "
    + alias4(((helper = (helper = lookupProperty(helpers,"displayName") || (depth0 != null ? lookupProperty(depth0,"displayName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"displayName","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":86,"column":58},"end":{"line":86,"column":75}}}) : helper)))
    + " </div>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"participantNameArray") : depth0),{"name":"each","hash":{},"fn":container.program(18, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":87,"column":36},"end":{"line":89,"column":45}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(data && lookupProperty(data,"last")),{"name":"unless","hash":{},"fn":container.program(13, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":90,"column":36},"end":{"line":92,"column":47}}})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data,blockParams) {
    return "                                        <div>"
    + container.escapeExpression(container.lambda(blockParams[0][0], depth0))
    + "</div>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"insurance") : depth0)) != null ? lookupProperty(stack1,"display") : stack1),{"name":"unless","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":98,"column":24},"end":{"line":107,"column":35}}})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"insurance") : depth0)) != null ? lookupProperty(stack1,"withoutText") : stack1),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":99,"column":28},"end":{"line":106,"column":35}}})) != null ? stack1 : "");
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"row detail-row\">\n                                    <div class=\"col-xs-4 label\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"general.insurance.travel",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":101,"column":64},"end":{"line":101,"column":112}}})) != null ? stack1 : "")
    + ":</div>\n                                    <div class=\"col-xs-8\">\n                                        "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"insurance") : depth0)) != null ? lookupProperty(stack1,"withoutText") : stack1), depth0))
    + "\n                                    </div>\n                                </div>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"cabin") : depth0),{"name":"each","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":115,"column":20},"end":{"line":180,"column":29}}})) != null ? stack1 : "");
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"participantPricesDetail") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":116,"column":24},"end":{"line":179,"column":31}}})) != null ? stack1 : "");
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"cabin-wrapper\">\n                                <div class=\"personal-offer-headline\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"general.cabin",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":118,"column":69},"end":{"line":118,"column":106}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"cabinIndex") || (depth0 != null ? lookupProperty(depth0,"cabinIndex") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"cabinIndex","hash":{},"data":data,"loc":{"start":{"line":118,"column":107},"end":{"line":118,"column":123}}}) : helper)))
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"priceModel") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":119,"column":32},"end":{"line":124,"column":39}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"cabinCategoryTypeName") : depth0),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":126,"column":32},"end":{"line":131,"column":39}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"details") : depth0),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":133,"column":32},"end":{"line":142,"column":39}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasDiscounts") : depth0),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":143,"column":32},"end":{"line":166,"column":39}}})) != null ? stack1 : "")
    + "                                <div class=\"row detail-row\">\n                                    <div class=\"col-xs-4 label\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.page.booking-page.layout.pp",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":168,"column":64},"end":{"line":168,"column":119}}})) != null ? stack1 : "")
    + ":</div>\n                                    <div class=\"col-xs-8\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"participantPricesDetail") : depth0),{"name":"each","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":170,"column":40},"end":{"line":175,"column":49}}})) != null ? stack1 : "")
    + "                                    </div>\n                                </div>\n                            </div>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <div class=\"row detail-row\">\n                                        <div class=\"col-xs-4 label\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.page.booking-page.layout.priceModel",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":121,"column":68},"end":{"line":121,"column":131}}})) != null ? stack1 : "")
    + ":</div>\n                                        <div class=\"col-xs-8\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"priceModel") || (depth0 != null ? lookupProperty(depth0,"priceModel") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"priceModel","hash":{},"data":data,"loc":{"start":{"line":122,"column":62},"end":{"line":122,"column":76}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"vip") : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":122,"column":76},"end":{"line":122,"column":104}}})) != null ? stack1 : "")
    + "</div>\n                                    </div>\n";
},"28":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " + "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"vip") || (depth0 != null ? lookupProperty(depth0,"vip") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"vip","hash":{},"data":data,"loc":{"start":{"line":122,"column":90},"end":{"line":122,"column":97}}}) : helper)));
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <div class=\"row detail-row\">\n                                        <div class=\"col-xs-4 label\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.page.booking-page.layout.cabinType",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":128,"column":68},"end":{"line":128,"column":130}}})) != null ? stack1 : "")
    + ":</div>\n                                        <div class=\"col-xs-8\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"cabinCategoryTypeName") || (depth0 != null ? lookupProperty(depth0,"cabinCategoryTypeName") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"cabinCategoryTypeName","hash":{},"data":data,"loc":{"start":{"line":129,"column":62},"end":{"line":129,"column":87}}}) : helper)))
    + "</div>\n                                    </div>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <div class=\"row detail-row\">\n                                        <div class=\"col-xs-4 label\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(alias1,"tpl.page.booking-page.layout.priceType",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":135,"column":68},"end":{"line":135,"column":130}}})) != null ? stack1 : "")
    + ":</div>\n                                        <div class=\"col-xs-8\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"details") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"cabinNumber") : depth0),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":137,"column":44},"end":{"line":139,"column":53}}})) != null ? stack1 : "")
    + "</div>\n                                    </div>\n";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ", "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"general.deck",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":138,"column":50},"end":{"line":138,"column":86}}})) != null ? stack1 : "")
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"deckNumber") || (depth0 != null ? lookupProperty(depth0,"deckNumber") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"deckNumber","hash":{},"data":data,"loc":{"start":{"line":138,"column":87},"end":{"line":138,"column":103}}}) : helper)))
    + ", "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"general.nr",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":138,"column":105},"end":{"line":138,"column":139}}})) != null ? stack1 : "")
    + ". "
    + alias4(((helper = (helper = lookupProperty(helpers,"cabinNumber") || (depth0 != null ? lookupProperty(depth0,"cabinNumber") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cabinNumber","hash":{},"data":data,"loc":{"start":{"line":138,"column":141},"end":{"line":138,"column":158}}}) : helper)));
},"35":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <div class=\"row detail-row\">\n                                        <div class=\"col-xs-4 label\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(alias1,"tpl.page.booking-page.layout.discounts",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":145,"column":68},"end":{"line":145,"column":130}}})) != null ? stack1 : "")
    + ":</div>\n                                        <div class=\"col-xs-8\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"participantPricesDetail") : depth0),{"name":"each","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":147,"column":44},"end":{"line":163,"column":53}}})) != null ? stack1 : "")
    + "                                        </div>\n                                    </div>\n";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasDiscounts") : depth0),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":148,"column":48},"end":{"line":152,"column":55}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"prices") : depth0),{"name":"each","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":153,"column":48},"end":{"line":162,"column":57}}})) != null ? stack1 : "");
},"37":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                    <div class=\"row price-participant\">\n                                                        <div class=\"col-xs-12\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"displayName") || (depth0 != null ? lookupProperty(depth0,"displayName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"displayName","hash":{},"data":data,"loc":{"start":{"line":150,"column":79},"end":{"line":150,"column":96}}}) : helper)))
    + "</div>\n                                                    </div>\n";
},"39":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"discounts") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":154,"column":52},"end":{"line":161,"column":59}}})) != null ? stack1 : "");
},"40":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"discounts") : depth0),{"name":"each","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":155,"column":56},"end":{"line":160,"column":65}}})) != null ? stack1 : "");
},"41":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                            <div class=\"row price-discount\">\n                                                                <div class=\"col-xs-6\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":157,"column":86},"end":{"line":157,"column":96}}}) : helper)))
    + "</div>\n                                                                <div class=\"col-xs-6 price-value\">"
    + alias3((lookupProperty(helpers,"formatPrice")||(depth0 && lookupProperty(depth0,"formatPrice"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"discount") : depth0),{"name":"formatPrice","hash":{},"data":data,"loc":{"start":{"line":158,"column":98},"end":{"line":158,"column":124}}}))
    + "</div>\n                                                            </div>\n";
},"43":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            <div class=\"row price-participant\">\n                                                <div class=\"col-xs-6 \">"
    + alias3(((helper = (helper = lookupProperty(helpers,"displayName") || (depth0 != null ? lookupProperty(depth0,"displayName") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"displayName","hash":{},"data":data,"loc":{"start":{"line":172,"column":71},"end":{"line":172,"column":88}}}) : helper)))
    + "</div>\n                                                <div class=\"col-xs-6 price-value\">"
    + alias3((lookupProperty(helpers,"formatPrice")||(depth0 && lookupProperty(depth0,"formatPrice"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"participantTotalPrice") : depth0),{"name":"formatPrice","hash":{},"data":data,"loc":{"start":{"line":173,"column":82},"end":{"line":173,"column":121}}}))
    + "</div>\n                                            </div>\n";
},"45":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"row detail-row\">\n                        <div class=\"col-xs-4 label\"></div>\n                        <div class=\"col-xs-8\">\n                            <div class=\"row price-total\">\n                                <div class=\"col-xs-6 \">\n                                    "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"general.total",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":189,"column":36},"end":{"line":189,"column":73}}})) != null ? stack1 : "")
    + "<br>\n                                </div>\n                                <div class=\"col-xs-6 price-value\"><span class=\"nobr\">"
    + container.escapeExpression((lookupProperty(helpers,"formatPrice")||(depth0 && lookupProperty(depth0,"formatPrice"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"totalBookingPrice") : depth0),{"name":"formatPrice","hash":{},"data":data,"loc":{"start":{"line":191,"column":85},"end":{"line":191,"column":120}}}))
    + "<sub>*</sub></span></div>\n                            </div>\n                        </div>\n                    </div>\n";
},"47":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"row table-values\">\n                        <div class=\"col-xs-2\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"displayShortdate") || (depth0 != null ? lookupProperty(depth0,"displayShortdate") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"displayShortdate","hash":{},"data":data,"loc":{"start":{"line":211,"column":46},"end":{"line":211,"column":68}}}) : helper)))
    + "</div>\n                        <div class=\"col-xs-3 "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"tenderPort") : depth0),{"name":"if","hash":{},"fn":container.program(48, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":212,"column":45},"end":{"line":212,"column":86}}})) != null ? stack1 : "")
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"portName") || (depth0 != null ? lookupProperty(depth0,"portName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"portName","hash":{},"data":data,"loc":{"start":{"line":212,"column":88},"end":{"line":212,"column":102}}}) : helper)))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"tenderPort") : depth0),{"name":"if","hash":{},"fn":container.program(50, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":212,"column":103},"end":{"line":212,"column":189}}})) != null ? stack1 : "")
    + "</div>\n                        <div class=\"col-xs-3\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"countryName") : depth0),{"name":"if","hash":{},"fn":container.program(52, data, 0),"inverse":container.program(54, data, 0),"data":data,"loc":{"start":{"line":213,"column":46},"end":{"line":213,"column":98}}})) != null ? stack1 : "")
    + "</div>\n                        <div class=\"col-xs-2\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"arrivalTime") : depth0),{"name":"if","hash":{},"fn":container.program(56, data, 0),"inverse":container.program(54, data, 0),"data":data,"loc":{"start":{"line":214,"column":46},"end":{"line":214,"column":98}}})) != null ? stack1 : "")
    + "</div>\n                        <div class=\"col-xs-2\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"departureTime") : depth0),{"name":"if","hash":{},"fn":container.program(58, data, 0),"inverse":container.program(54, data, 0),"data":data,"loc":{"start":{"line":215,"column":46},"end":{"line":215,"column":102}}})) != null ? stack1 : "")
    + "</div>\n                    </div>\n";
},"48":function(container,depth0,helpers,partials,data) {
    return "tender-info-port";
},"50":function(container,depth0,helpers,partials,data) {
    return "<span class=\"tender-info-icon icon icon-tender-hafen\"></span>";
},"52":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"countryName") || (depth0 != null ? lookupProperty(depth0,"countryName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"countryName","hash":{},"data":data,"loc":{"start":{"line":213,"column":65},"end":{"line":213,"column":82}}}) : helper)));
},"54":function(container,depth0,helpers,partials,data) {
    return "-";
},"56":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"arrivalTime") || (depth0 != null ? lookupProperty(depth0,"arrivalTime") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"arrivalTime","hash":{},"data":data,"loc":{"start":{"line":214,"column":65},"end":{"line":214,"column":82}}}) : helper)));
},"58":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"departureTime") || (depth0 != null ? lookupProperty(depth0,"departureTime") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"departureTime","hash":{},"data":data,"loc":{"start":{"line":215,"column":67},"end":{"line":215,"column":86}}}) : helper)));
},"60":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"tender-info\">\n                    <span class=\"icon icon-tender-hafen\"></span>\n                    <span>\n                        "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"tpl.page.booking-page.layout.tenderInfo",{"name":"formatMessage","hash":{"ship":((stack1 = (depth0 != null ? lookupProperty(depth0,"trip") : depth0)) != null ? lookupProperty(stack1,"ship") : stack1)},"data":data,"loc":{"start":{"line":224,"column":24},"end":{"line":224,"column":102}}})) != null ? stack1 : "")
    + "\n                    </span>\n                </div>\n";
},"62":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"agency") : depth0),{"name":"with","hash":{},"fn":container.program(63, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":230,"column":16},"end":{"line":242,"column":25}}})) != null ? stack1 : "");
},"63":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"right-box\">\n                        <div class=\"box-headline\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.page.booking-page.layout.my-traveloffice",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":232,"column":50},"end":{"line":232,"column":118}}})) != null ? stack1 : "")
    + "</div>\n                        <div class=\"name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":233,"column":42},"end":{"line":233,"column":52}}}) : helper)))
    + "</div>\n                        <div>"
    + alias4(((helper = (helper = lookupProperty(helpers,"addressLine1") || (depth0 != null ? lookupProperty(depth0,"addressLine1") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"addressLine1","hash":{},"data":data,"loc":{"start":{"line":234,"column":29},"end":{"line":234,"column":47}}}) : helper)))
    + "</div>\n                        <div>"
    + alias4(((helper = (helper = lookupProperty(helpers,"postCode") || (depth0 != null ? lookupProperty(depth0,"postCode") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"postCode","hash":{},"data":data,"loc":{"start":{"line":235,"column":29},"end":{"line":235,"column":43}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"city") || (depth0 != null ? lookupProperty(depth0,"city") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"city","hash":{},"data":data,"loc":{"start":{"line":235,"column":44},"end":{"line":235,"column":54}}}) : helper)))
    + "</div>\n                        <div>"
    + alias4(((helper = (helper = lookupProperty(helpers,"addressLine2") || (depth0 != null ? lookupProperty(depth0,"addressLine2") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"addressLine2","hash":{},"data":data,"loc":{"start":{"line":236,"column":29},"end":{"line":236,"column":47}}}) : helper)))
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"telephone") : depth0),{"name":"if","hash":{},"fn":container.program(64, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":237,"column":24},"end":{"line":238,"column":113}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"email") : depth0),{"name":"if","hash":{},"fn":container.program(66, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":239,"column":24},"end":{"line":240,"column":108}}})) != null ? stack1 : "")
    + "\n                    </div>\n";
},"64":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div><a class=\"underline\" href=\"tel:"
    + alias4(((helper = (helper = lookupProperty(helpers,"telephone") || (depth0 != null ? lookupProperty(depth0,"telephone") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"telephone","hash":{},"data":data,"loc":{"start":{"line":238,"column":64},"end":{"line":238,"column":79}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"telephone") || (depth0 != null ? lookupProperty(depth0,"telephone") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"telephone","hash":{},"data":data,"loc":{"start":{"line":238,"column":81},"end":{"line":238,"column":96}}}) : helper)))
    + "</a></div>";
},"66":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div><a class=\"underline\" href=\"mailto:"
    + alias4(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"email","hash":{},"data":data,"loc":{"start":{"line":240,"column":67},"end":{"line":240,"column":78}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"email","hash":{},"data":data,"loc":{"start":{"line":240,"column":80},"end":{"line":240,"column":91}}}) : helper)))
    + "</a></div>";
},"68":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"right-box\">\n                    <div class=\"box-headline\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"cabin") : depth0)) != null ? lookupProperty(stack1,"priceModel") : stack1), depth0))
    + "</div>\n                    <ul class=\"checkmark-list\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias2,(depth0 != null ? lookupProperty(depth0,"b2cTexte") : depth0),{"name":"each","hash":{},"fn":container.program(69, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":247,"column":24},"end":{"line":249,"column":33}}})) != null ? stack1 : "")
    + "                    </ul>\n                    "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias3).call(alias2,"tpl.page.booking-page.layout.priceModel.info",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":251,"column":20},"end":{"line":251,"column":87}}})) != null ? stack1 : "")
    + "\n                </div>\n\n                <div class=\"right-box\">\n                    <div class=\"box-headline\">\n                        "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias3).call(alias2,"tpl.page.booking-page.layout.contact.headline",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":256,"column":24},"end":{"line":256,"column":93}}})) != null ? stack1 : "")
    + "\n                    </div>\n                    <div>"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias3).call(alias2,"tpl.page.booking-page.layout.contact.phone",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":258,"column":25},"end":{"line":258,"column":91}}})) != null ? stack1 : "")
    + ": "
    + alias1((lookupProperty(helpers,"getConstants")||(depth0 && lookupProperty(depth0,"getConstants"))||alias3).call(alias2,"VIEW_SERVICE_NUMBER_DISPLAY_INT",{"name":"getConstants","hash":{},"data":data,"loc":{"start":{"line":258,"column":93},"end":{"line":258,"column":143}}}))
    + "</div>\n                    <div>"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias3).call(alias2,"tpl.page.booking-page.layout.contact.mail",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":259,"column":25},"end":{"line":259,"column":90}}})) != null ? stack1 : "")
    + ": info@tuicruises.com</div>\n                </div>\n";
},"69":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <li>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"value","hash":{},"data":data,"loc":{"start":{"line":248,"column":32},"end":{"line":248,"column":43}}}) : helper)))
    + "</li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"container container-with-padding page-header-logo-bar\">\n    <svg width=\"165\" height=\"60\">\n        <use xlink:href=\"#meinSchiffLogo\"></use>\n    </svg>\n</div>\n\n<div class=\"container container-with-padding booking-page\">\n    <div class=\"row\">\n        <div class=\"col-md-12\">\n            <h1 class=\"booking-page-headline\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"trip") : depth0)) != null ? lookupProperty(stack1,"tripName") : stack1), depth0))
    + " <i>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"trip") : depth0)) != null ? lookupProperty(stack1,"ship") : stack1), depth0))
    + "</i></h1>\n        </div>\n    </div>\n    <div class=\"row\">\n        <div class=\"col-xs-12 offer-headline\">\n            <p>"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias4).call(alias3,"tpl.page.booking-page.content.print-offer.date",{"name":"formatMessage","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":15,"column":15},"end":{"line":15,"column":85}}})) != null ? stack1 : "")
    + ": "
    + alias2((lookupProperty(helpers,"formatDate")||(depth0 && lookupProperty(depth0,"formatDate"))||alias4).call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"trip") : depth0)) != null ? lookupProperty(stack1,"travelDates") : stack1)) != null ? lookupProperty(stack1,"from") : stack1),"mediumDate",{"name":"formatDate","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":15,"column":87},"end":{"line":15,"column":137}}}))
    + " – "
    + alias2((lookupProperty(helpers,"formatDate")||(depth0 && lookupProperty(depth0,"formatDate"))||alias4).call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"trip") : depth0)) != null ? lookupProperty(stack1,"travelDates") : stack1)) != null ? lookupProperty(stack1,"to") : stack1),"mediumDate",{"name":"formatDate","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":15,"column":140},"end":{"line":15,"column":188}}}))
    + "</p>\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div class=\"col-xs-6\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"trip") : depth0)) != null ? lookupProperty(stack1,"routeMapURL") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":21,"column":12},"end":{"line":23,"column":19}}})) != null ? stack1 : "")
    + "            <div class=\"personal-offer\">\n                <div class=\"row detail-row\">\n                    <div class=\"col-xs-12\">\n                        <div class=\"personal-offer-headline\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias4).call(alias3,"tpl.page.booking-page.content.print-offer.headline",{"name":"formatMessage","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":27,"column":61},"end":{"line":27,"column":135}}})) != null ? stack1 : "")
    + "</div>\n                        <div>"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias4).call(alias3,"tpl.page.booking-page.content.print-offer.description",{"name":"formatMessage","hash":{"ship":((stack1 = (depth0 != null ? lookupProperty(depth0,"trip") : depth0)) != null ? lookupProperty(stack1,"ship") : stack1)},"data":data,"blockParams":blockParams,"loc":{"start":{"line":28,"column":29},"end":{"line":28,"column":121}}})) != null ? stack1 : "")
    + "</div>\n                    </div>\n                </div>\n                <div class=\"row detail-row\">\n                    <div class=\"col-xs-4 label\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias4).call(alias3,"tpl.page.booking-page.layout.travelers",{"name":"formatMessage","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":35,"column":48},"end":{"line":35,"column":110}}})) != null ? stack1 : "")
    + ":</div>\n                    <div class=\"col-xs-8\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"partySummary") || (depth0 != null ? lookupProperty(depth0,"partySummary") : depth0)) != null ? helper : alias4),(typeof helper === "function" ? helper.call(alias3,{"name":"partySummary","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":36,"column":42},"end":{"line":36,"column":58}}}) : helper)))
    + "</div>\n                </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"travel") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":42,"column":16},"end":{"line":74,"column":23}}})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"insurance") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":80,"column":16},"end":{"line":109,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"cabin") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":114,"column":16},"end":{"line":181,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"totalBookingPrice") : depth0),{"name":"if","hash":{},"fn":container.program(45, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":183,"column":16},"end":{"line":195,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n\n        <div class=\"col-xs-6\">\n            <div class=\"travel-itinerary-title\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"trip") : depth0)) != null ? lookupProperty(stack1,"numberOfNights") : stack1), depth0))
    + " "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias4).call(alias3,"general.nights",{"name":"formatMessage","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":200,"column":74},"end":{"line":200,"column":112}}})) != null ? stack1 : "")
    + "</div>\n            <div class=\"travel-itinerary\">\n                <div class=\"row table-headline\">\n                    <div class=\"col-xs-2\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias4).call(alias3,"tpl.page.booking-page.layout.day",{"name":"formatMessage","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":203,"column":42},"end":{"line":203,"column":98}}})) != null ? stack1 : "")
    + "</div>\n                    <div class=\"col-xs-3\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias4).call(alias3,"tpl.page.booking-page.layout.tenderPort",{"name":"formatMessage","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":204,"column":42},"end":{"line":204,"column":105}}})) != null ? stack1 : "")
    + "</div>\n                    <div class=\"col-xs-3\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias4).call(alias3,"tpl.page.booking-page.layout.country",{"name":"formatMessage","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":205,"column":42},"end":{"line":205,"column":102}}})) != null ? stack1 : "")
    + "</div>\n                    <div class=\"col-xs-2\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias4).call(alias3,"tpl.page.booking-page.layout.arrival",{"name":"formatMessage","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":206,"column":42},"end":{"line":206,"column":102}}})) != null ? stack1 : "")
    + "</div>\n                    <div class=\"col-xs-2\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias4).call(alias3,"tpl.page.booking-page.layout.departure",{"name":"formatMessage","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":207,"column":42},"end":{"line":207,"column":104}}})) != null ? stack1 : "")
    + "</div>\n                </div>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"trip") : depth0)) != null ? lookupProperty(stack1,"itinerary") : stack1),{"name":"each","hash":{},"fn":container.program(47, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":209,"column":16},"end":{"line":217,"column":25}}})) != null ? stack1 : "")
    + "            </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"hasTender") : depth0),{"name":"if","hash":{},"fn":container.program(60, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":220,"column":12},"end":{"line":227,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isB2B") : depth0),{"name":"if","hash":{},"fn":container.program(62, data, 0, blockParams),"inverse":container.program(68, data, 0, blockParams),"data":data,"blockParams":blockParams,"loc":{"start":{"line":229,"column":12},"end":{"line":261,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n\n<br><br>\n<div class=\"container container-with-padding\">\n    <div class=\"benefit\">\n        <div class=\"benefit-headline\">\n            <i class=\"bold\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias4).call(alias3,"general.mein-schiff",{"name":"formatMessage","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":270,"column":28},"end":{"line":270,"column":71}}})) != null ? stack1 : "")
    + "</i> <sup>&reg;</sup> "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias4).call(alias3,"tpl.page.booking-page.layout.premium",{"name":"formatMessage","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":270,"column":93},"end":{"line":270,"column":153}}})) != null ? stack1 : "")
    + "\n        </div>\n        <div class=\"row\">\n            <div class=\"col-xs-4\">\n                <li><span class=\"icon icon-genuss\"></span><span class=\"text\"><i>"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias4).call(alias3,"general.mein-schiff",{"name":"formatMessage","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":274,"column":80},"end":{"line":274,"column":123}}})) != null ? stack1 : "")
    + "</i> <sup>&reg;</sup> "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias4).call(alias3,"components.overview.premium.genussvielfalt",{"name":"formatMessage","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":274,"column":145},"end":{"line":274,"column":211}}})) != null ? stack1 : "")
    + "</span></li>\n                <li><span class=\"icon icon-zeitplan\"></span><span class=\"text\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias4).call(alias3,"components.overview.premium.geniessen",{"name":"formatMessage","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":275,"column":79},"end":{"line":275,"column":140}}})) != null ? stack1 : "")
    + "</span></li>\n                <li><span class=\"icon icon-getraenke\"></span><span class=\"text\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias4).call(alias3,"components.overview.premium.markengetraenke",{"name":"formatMessage","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":276,"column":80},"end":{"line":276,"column":147}}})) != null ? stack1 : "")
    + "</span></li>\n                <li><span class=\"icon icon-kaffee\"></span><span class=\"text\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias4).call(alias3,"components.overview.premium.kaffee",{"name":"formatMessage","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":277,"column":77},"end":{"line":277,"column":135}}})) != null ? stack1 : "")
    + "</span></li>\n                <li><span class=\"icon icon-eis\"></span><span class=\"text\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias4).call(alias3,"components.overview.premium.eis",{"name":"formatMessage","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":278,"column":74},"end":{"line":278,"column":129}}})) != null ? stack1 : "")
    + "</span></li>\n            </div>\n            <div class=\"col-xs-4\">\n                <li><span class=\"icon icon-allergiker\"></span><span class=\"text\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias4).call(alias3,"components.overview.premium.allergiker",{"name":"formatMessage","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":281,"column":81},"end":{"line":281,"column":143}}})) != null ? stack1 : "")
    + "</span></li>\n                <li><span class=\"icon icon-espresso\"></span><span class=\"text\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias4).call(alias3,"components.overview.premium.espressomaschine",{"name":"formatMessage","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":282,"column":79},"end":{"line":282,"column":147}}})) != null ? stack1 : "")
    + "</span></li>\n                <li><span class=\"icon icon-kabinenservice\"></span><span class=\"text\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias4).call(alias3,"components.overview.premium.kabinenservice",{"name":"formatMessage","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":283,"column":85},"end":{"line":283,"column":151}}})) != null ? stack1 : "")
    + "</span></li>\n                <li><span class=\"icon icon-kofferservice\"></span><span class=\"text\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias4).call(alias3,"components.overview.premium.kofferservice",{"name":"formatMessage","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":284,"column":84},"end":{"line":284,"column":149}}})) != null ? stack1 : "")
    + "</span></li>\n                <li><span class=\"icon icon-spa\"></span><span class=\"text\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias4).call(alias3,"components.overview.premium.spa",{"name":"formatMessage","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":285,"column":74},"end":{"line":285,"column":129}}})) != null ? stack1 : "")
    + "</span></li>\n            </div>\n            <div class=\"col-xs-4\">\n                <li><span class=\"icon icon-sport\"></span><span class=\"text\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias4).call(alias3,"components.overview.premium.sport",{"name":"formatMessage","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":288,"column":76},"end":{"line":288,"column":133}}})) != null ? stack1 : "")
    + "</span></li>\n                <li><span class=\"icon icon-programm\"></span><span class=\"text\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias4).call(alias3,"components.overview.premium.programm",{"name":"formatMessage","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":289,"column":79},"end":{"line":289,"column":139}}})) != null ? stack1 : "")
    + "</span></li>\n                <li><span class=\"icon icon-entertainment\"></span><span class=\"text\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias4).call(alias3,"components.overview.premium.entertainment",{"name":"formatMessage","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":290,"column":84},"end":{"line":290,"column":149}}})) != null ? stack1 : "")
    + "</span></li>\n                <li><span class=\"icon icon-kinderbetreuung\"></span><span class=\"text\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias4).call(alias3,"components.overview.premium.kinderbetreuung",{"name":"formatMessage","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":291,"column":86},"end":{"line":291,"column":153}}})) != null ? stack1 : "")
    + "</span></li>\n                <li><span class=\"icon icon-trinkgeld\"></span><span class=\"text\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias4).call(alias3,"components.overview.premium.trinkgeld",{"name":"formatMessage","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":292,"column":80},"end":{"line":292,"column":141}}})) != null ? stack1 : "")
    + "</span></li>\n            </div>\n        </div>\n    </div>\n</div>\n</div>\n\n<br>\n<div class=\"container container-with-padding\">\n    <div class=\"offer-small\">\n        "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias4).call(alias3,"tpl.page.booking-page.layout.disclaimer",{"name":"formatMessage","hash":{"a":"#fnc#<a class=\"underline\" href=\"https://meinschiff.com/preismodelle\" rel=\"noopener noreferrer\">$chunk$</a>","date":(depth0 != null ? lookupProperty(depth0,"date") : depth0)},"data":data,"blockParams":blockParams,"loc":{"start":{"line":302,"column":8},"end":{"line":302,"column":192}}})) != null ? stack1 : "")
    + "\n    </div>\n</div>\n\n<div class=\"page-footer-link-bar\">\n    <div class=\"container container-with-padding\">\n        <div class=\"row\">\n            <div class=\"col-sm-5 col-sm-push-7 align-right\">\n                <svg width=\"220\" height=\"54\" class=\"logo-tui-cruises\">\n                    <use xlink:href=\"#icon-logo-tuic\"></use>\n                </svg>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true,"useBlockParams":true});