var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "is-selected";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"badge\">\n            <svg>\n                <use xmlns:xlink=\"http://www.w3.org/1999/xlink\" xlink:href=\"#icon-badge_bg\"></use>\n            </svg>\n            <p>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"badgeText") || (depth0 != null ? lookupProperty(depth0,"badgeText") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"badgeText","hash":{},"data":data,"loc":{"start":{"line":8,"column":15},"end":{"line":8,"column":28}}}) : helper)))
    + "</p>\n        </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isShipbound") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":18,"column":15}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <h2 class=\"headline\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"tpl.item.travel-flight-option.headline.to",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":15,"column":33},"end":{"line":15,"column":98}}})) != null ? stack1 : "")
    + "</h2>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <h2 class=\"headline\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"tpl.item.travel-flight-option.headline.from",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":17,"column":33},"end":{"line":17,"column":100}}})) != null ? stack1 : "")
    + "</h2>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <img alt=\"Airline\" class=\"airline-logo\" src=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"departureAirlineLogo") || (depth0 != null ? lookupProperty(depth0,"departureAirlineLogo") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"departureAirlineLogo","hash":{},"data":data,"loc":{"start":{"line":24,"column":57},"end":{"line":24,"column":81}}}) : helper)))
    + "\"/>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isPlaceholderFlight") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":8},"end":{"line":30,"column":8}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"flight-details-unavailable js-flight-details\">\n                <span class=\"icon icon-exclamation-triangle\"></span>\n                <span>"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"tpl.item.travel-flight-option.unknown",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":28,"column":22},"end":{"line":28,"column":83}}})) != null ? stack1 : "")
    + "</span>\n            </div>\n        ";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression((lookupProperty(helpers,"formatDate")||(depth0 && lookupProperty(depth0,"formatDate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"departureTime") : depth0),"shortTime",{"name":"formatDate","hash":{},"data":data,"loc":{"start":{"line":42,"column":24},"end":{"line":42,"column":64}}}))
    + "\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "                        – : –\n";
},"19":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + alias4(((helper = (helper = lookupProperty(helpers,"departureAirport") || (depth0 != null ? lookupProperty(depth0,"departureAirport") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"departureAirport","hash":{},"data":data,"loc":{"start":{"line":51,"column":24},"end":{"line":51,"column":44}}}) : helper)))
    + " ("
    + alias4(((helper = (helper = lookupProperty(helpers,"departureAirportCode") || (depth0 != null ? lookupProperty(depth0,"departureAirportCode") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"departureAirportCode","hash":{},"data":data,"loc":{"start":{"line":51,"column":46},"end":{"line":51,"column":70}}}) : helper)))
    + ")\n";
},"21":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"flight-detail flight-detail-layover\">\n                    <div class=\"bullet\"></div>\n                    <span class=\"icon icon-plane-landing\"></span>\n                    "
    + alias4(((helper = (helper = lookupProperty(helpers,"layoverStoppsText") || (depth0 != null ? lookupProperty(depth0,"layoverStoppsText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"layoverStoppsText","hash":{},"data":data,"loc":{"start":{"line":60,"column":20},"end":{"line":60,"column":41}}}) : helper)))
    + " ("
    + alias4(((helper = (helper = lookupProperty(helpers,"layoverStoppsAirports") || (depth0 != null ? lookupProperty(depth0,"layoverStoppsAirports") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"layoverStoppsAirports","hash":{},"data":data,"loc":{"start":{"line":60,"column":43},"end":{"line":60,"column":68}}}) : helper)))
    + ")\n                </div>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression((lookupProperty(helpers,"formatDate")||(depth0 && lookupProperty(depth0,"formatDate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"arrivalTime") : depth0),"shortTime",{"name":"formatDate","hash":{},"data":data,"loc":{"start":{"line":72,"column":24},"end":{"line":72,"column":62}}}))
    + "\n";
},"25":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + alias4(((helper = (helper = lookupProperty(helpers,"arrivalAirport") || (depth0 != null ? lookupProperty(depth0,"arrivalAirport") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"arrivalAirport","hash":{},"data":data,"loc":{"start":{"line":81,"column":24},"end":{"line":81,"column":42}}}) : helper)))
    + " ("
    + alias4(((helper = (helper = lookupProperty(helpers,"arrivalAirportCode") || (depth0 != null ? lookupProperty(depth0,"arrivalAirportCode") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"arrivalAirportCode","hash":{},"data":data,"loc":{"start":{"line":81,"column":44},"end":{"line":81,"column":66}}}) : helper)))
    + ")\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div><b>"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.overlay.travel-flight.class",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":89,"column":24},"end":{"line":89,"column":84}}})) != null ? stack1 : "")
    + "</b> "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"soleAvailableClass") || (depth0 != null ? lookupProperty(depth0,"soleAvailableClass") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"soleAvailableClass","hash":{},"data":data,"loc":{"start":{"line":89,"column":89},"end":{"line":89,"column":111}}}) : helper)))
    + "</div>\n";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"select-element\">\n                    <select class=\"form-element js-select-flight-class\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"flightClasses") : depth0),{"name":"each","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":93,"column":24},"end":{"line":95,"column":33}}})) != null ? stack1 : "")
    + "                    </select>\n                </div>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":94,"column":43},"end":{"line":94,"column":49}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"displayed") : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":94,"column":51},"end":{"line":94,"column":83}}})) != null ? stack1 : "")
    + " >"
    + alias4(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":94,"column":85},"end":{"line":94,"column":94}}}) : helper)))
    + "</option>\n";
},"31":function(container,depth0,helpers,partials,data) {
    return "selected";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a class=\"button button-secondary js-flight-details\">\n                    <span>"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"tpl.item.travel-flight-option.details",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":102,"column":26},"end":{"line":102,"column":87}}})) != null ? stack1 : "")
    + "</span><span class=\"icon-small-arrow-right\"></span>\n                </a>\n";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"actions-wrapper show\">\n            <div class=\"actions\">\n\n                <div class=\"flight-price\">\n                    <span class=\"flight-price-info\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"priceInfo") || (depth0 != null ? lookupProperty(depth0,"priceInfo") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"priceInfo","hash":{},"data":data,"loc":{"start":{"line":114,"column":52},"end":{"line":114,"column":65}}}) : helper)))
    + "</span>\n                    <span class=\"nobr\">"
    + alias3((lookupProperty(helpers,"formatPrice")||(depth0 && lookupProperty(depth0,"formatPrice"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"price") : depth0),{"name":"formatPrice","hash":{},"data":data,"loc":{"start":{"line":115,"column":39},"end":{"line":115,"column":60}}}))
    + "</span>\n                </div>\n\n                <div class=\"flight-choice\">\n                    <a class=\"button js-select-flight\">\n                        <span>"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.travel-flight-option.select",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":120,"column":30},"end":{"line":120,"column":90}}})) != null ? stack1 : "")
    + "</span><span class=\"icon-small-arrow-right\"></span>\n                    </a>\n                </div>\n\n            </div>\n\n            <div class=\"price-per-person\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.travel-flight-option.pp",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":126,"column":42},"end":{"line":126,"column":98}}})) != null ? stack1 : "")
    + "</div>\n        </div>\n";
},"37":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"trainToPlaneLegacy") : depth0),{"name":"if","hash":{},"fn":container.program(38, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":131,"column":8},"end":{"line":154,"column":15}}})) != null ? stack1 : "")
    + "\n        <div class=\"train-upgrade\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"trainToPlaneUpgradeOptions") : depth0),{"name":"if","hash":{},"fn":container.program(48, data, 0, blockParams, depths),"inverse":container.program(60, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":157,"column":12},"end":{"line":189,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n\n";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"trainToPlaneBlockedOnline") : depth0),{"name":"unless","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":132,"column":12},"end":{"line":153,"column":23}}})) != null ? stack1 : "");
},"39":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"train-upgrade\" style=\"background-color: rgb(226 226 226); opacity: 0.6;\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"trainToPlaneUpgradePrice") : depth0),{"name":"if","hash":{},"fn":container.program(40, data, 0),"inverse":container.program(43, data, 0),"data":data,"loc":{"start":{"line":134,"column":20},"end":{"line":149,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"trainToPlanePurHint") : depth0),{"name":"if","hash":{},"fn":container.program(46, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":150,"column":20},"end":{"line":151,"column":88}}})) != null ? stack1 : "")
    + "\n                </div>\n";
},"40":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"radiobutton-element "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"trainToPlaneUpgradeSelected") : depth0),{"name":"unless","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":135,"column":56},"end":{"line":135,"column":113}}})) != null ? stack1 : "")
    + "\">\n                            <input type=\"radio\" value=\"0\" name=\"old-train-upgrade-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":136,"column":82},"end":{"line":136,"column":88}}}) : helper)))
    + "\" id=\"train-upgrade-no-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":136,"column":111},"end":{"line":136,"column":117}}}) : helper)))
    + "\" class=\"js-train-upgrade-radio\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"trainToPlaneUpgradeSelected") : depth0),{"name":"unless","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":136,"column":150},"end":{"line":136,"column":207}}})) != null ? stack1 : "")
    + ">\n                            <label for=\"train-upgrade-no-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":137,"column":57},"end":{"line":137,"column":63}}}) : helper)))
    + "\">\n                                "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.travel-flight-option.trainToPlaneUpgradePrice",{"name":"formatMessage","hash":{"trainToPlaneDefaultClassIncluded":(depth0 != null ? lookupProperty(depth0,"trainToPlaneDefaultClassIncluded") : depth0)},"data":data,"loc":{"start":{"line":138,"column":32},"end":{"line":138,"column":176}}})) != null ? stack1 : "")
    + "&nbsp;<span class=\"icon-info js-overlay-flight-train\"></span>&nbsp;&nbsp;<span class=\"break\">("
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.travel-flight-option.trainToPlaneUpgradePrice.included",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":138,"column":270},"end":{"line":138,"column":357}}})) != null ? stack1 : "")
    + ")</span>\n                            </label>\n                        </div>\n                        <div class=\"radiobutton-element "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"trainToPlaneUpgradeSelected") : depth0),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":141,"column":56},"end":{"line":141,"column":105}}})) != null ? stack1 : "")
    + "\">\n                            <input type=\"radio\" value=\"1\" name=\"old-train-upgrade-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":142,"column":82},"end":{"line":142,"column":88}}}) : helper)))
    + "\" id=\"train-upgrade-yes-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":142,"column":112},"end":{"line":142,"column":118}}}) : helper)))
    + "\" class=\"js-train-upgrade-radio\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"trainToPlaneUpgradeSelected") : depth0),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":142,"column":151},"end":{"line":142,"column":200}}})) != null ? stack1 : "")
    + ">\n                            <label for=\"train-upgrade-yes-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":143,"column":58},"end":{"line":143,"column":64}}}) : helper)))
    + "\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.travel-flight-option.trainToPlaneUpgradeSelected",{"name":"formatMessage","hash":{"class":(depth0 != null ? lookupProperty(depth0,"firstClassNumberLocalized") : depth0)},"data":data,"loc":{"start":{"line":143,"column":66},"end":{"line":143,"column":179}}})) != null ? stack1 : "")
    + " <span class=\"break\">("
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.travel-flight-option.trainToPlaneUpgradeSelected.additional",{"name":"formatMessage","hash":{"trainToPlaneUpgradePrice":(depth0 != null ? lookupProperty(depth0,"trainToPlaneUpgradePrice") : depth0)},"data":data,"loc":{"start":{"line":143,"column":201},"end":{"line":143,"column":343}}})) != null ? stack1 : "")
    + ")</span></label>\n                        </div>\n";
},"41":function(container,depth0,helpers,partials,data) {
    return "checked";
},"43":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"trainToPlaneDefaultClassIncluded") : depth0),{"name":"if","hash":{},"fn":container.program(44, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":146,"column":24},"end":{"line":148,"column":31}}})) != null ? stack1 : "");
},"44":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <h2 class=\"train-upgrade-headline hint help-hint js-overlay-flight-train\"><a>"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"tpl.item.travel-flight-option.trainToPlaneDefaultClassIncluded",{"name":"formatMessage","hash":{"trainToPlaneDefaultClassIncluded":(depth0 != null ? lookupProperty(depth0,"trainToPlaneDefaultClassIncluded") : depth0)},"data":data,"loc":{"start":{"line":147,"column":105},"end":{"line":147,"column":257}}})) != null ? stack1 : "")
    + "</a></h2>\n";
},"46":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"smaller-text\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"trainToPlanePurHint") || (depth0 != null ? lookupProperty(depth0,"trainToPlanePurHint") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"trainToPlanePurHint","hash":{},"data":data,"loc":{"start":{"line":151,"column":50},"end":{"line":151,"column":75}}}) : helper)))
    + "</div>";
},"48":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"trainToPlaneDefaultClassIncluded") : depth0),{"name":"if","hash":{},"fn":container.program(49, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":158,"column":16},"end":{"line":166,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"trainToPlaneUpgradeOptions") : depth0),{"name":"each","hash":{},"fn":container.program(54, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":167,"column":16},"end":{"line":184,"column":25}}})) != null ? stack1 : "");
},"49":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"radiobutton-element \">\n                        <input type=\"radio\" value=\"none\" name=\"train-upgrade-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":160,"column":77},"end":{"line":160,"column":83}}}) : helper)))
    + "\" id=\"train-upgrade-"
    + alias4(((helper = (helper = lookupProperty(helpers,"trainToPlaneDefaultClassIncluded") || (depth0 != null ? lookupProperty(depth0,"trainToPlaneDefaultClassIncluded") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"trainToPlaneDefaultClassIncluded","hash":{},"data":data,"loc":{"start":{"line":160,"column":103},"end":{"line":160,"column":139}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isShipbound") : depth0),{"name":"if","hash":{},"fn":container.program(50, data, 0),"inverse":container.program(52, data, 0),"data":data,"loc":{"start":{"line":160,"column":139},"end":{"line":160,"column":175}}})) != null ? stack1 : "")
    + "\" class=\"js-train-upgrade-radio\" checked>\n                        <label for=\"train-upgrade-"
    + alias4(((helper = (helper = lookupProperty(helpers,"trainToPlaneDefaultClassIncluded") || (depth0 != null ? lookupProperty(depth0,"trainToPlaneDefaultClassIncluded") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"trainToPlaneDefaultClassIncluded","hash":{},"data":data,"loc":{"start":{"line":161,"column":50},"end":{"line":161,"column":86}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isShipbound") : depth0),{"name":"if","hash":{},"fn":container.program(50, data, 0),"inverse":container.program(52, data, 0),"data":data,"loc":{"start":{"line":161,"column":86},"end":{"line":161,"column":122}}})) != null ? stack1 : "")
    + "\">\n                            "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.travel-flight-option.trainToPlaneUpgradePrice",{"name":"formatMessage","hash":{"trainToPlaneDefaultClassIncluded":(depth0 != null ? lookupProperty(depth0,"trainToPlaneDefaultClassIncluded") : depth0)},"data":data,"loc":{"start":{"line":162,"column":28},"end":{"line":162,"column":172}}})) != null ? stack1 : "")
    + "&nbsp;\n                            <span class=\"icon-info js-overlay-flight-train\"></span>&nbsp;&nbsp;<span class=\"break\">("
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.travel-flight-option.trainToPlaneUpgradePrice.included",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":163,"column":116},"end":{"line":163,"column":203}}})) != null ? stack1 : "")
    + ")</span>\n                        </label>\n                    </div>\n";
},"50":function(container,depth0,helpers,partials,data) {
    return "H";
},"52":function(container,depth0,helpers,partials,data) {
    return "R";
},"54":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"price") : depth0),{"name":"if","hash":{},"fn":container.program(55, data, 0, blockParams, depths),"inverse":container.program(58, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":168,"column":20},"end":{"line":183,"column":27}}})) != null ? stack1 : "");
},"55":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"radiobutton-element \">\n                            <input type=\"radio\" value="
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":170,"column":54},"end":{"line":170,"column":60}}}) : helper)))
    + " name=\"train-upgrade-"
    + alias4(((helper = (helper = lookupProperty(helpers,"selectedFlightId") || (depth0 != null ? lookupProperty(depth0,"selectedFlightId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"selectedFlightId","hash":{},"data":data,"loc":{"start":{"line":170,"column":81},"end":{"line":170,"column":101}}}) : helper)))
    + "\" id=\"train-upgrade-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":170,"column":121},"end":{"line":170,"column":127}}}) : helper)))
    + "\" class=\"js-train-upgrade-radio\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isSelectet") : depth0),{"name":"if","hash":{},"fn":container.program(56, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":170,"column":160},"end":{"line":170,"column":194}}})) != null ? stack1 : "")
    + ">\n                            <label for=\"train-upgrade-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":171,"column":54},"end":{"line":171,"column":60}}}) : helper)))
    + "\">\n                                "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.travel-flight-option.trainToPlaneUpgradeSelected",{"name":"formatMessage","hash":{"class":(depth0 != null ? lookupProperty(depth0,"class") : depth0)},"data":data,"loc":{"start":{"line":172,"column":32},"end":{"line":172,"column":125}}})) != null ? stack1 : "")
    + "&nbsp;\n                                <span class=\"break\">("
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.travel-flight-option.trainToPlaneUpgradeOptions.additional",{"name":"formatMessage","hash":{"price":(depth0 != null ? lookupProperty(depth0,"price") : depth0)},"data":data,"loc":{"start":{"line":173,"column":53},"end":{"line":173,"column":156}}})) != null ? stack1 : "")
    + ")</span>\n                            </label>\n                        </div>\n";
},"56":function(container,depth0,helpers,partials,data) {
    return " checked ";
},"58":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"radiobutton-element \">\n                            <input type=\"radio\" value="
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":178,"column":54},"end":{"line":178,"column":60}}}) : helper)))
    + " name=\"train-upgrade-"
    + alias4(((helper = (helper = lookupProperty(helpers,"selectedFlightId") || (depth0 != null ? lookupProperty(depth0,"selectedFlightId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"selectedFlightId","hash":{},"data":data,"loc":{"start":{"line":178,"column":81},"end":{"line":178,"column":101}}}) : helper)))
    + "\" id=\"train-upgrade-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":178,"column":121},"end":{"line":178,"column":127}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"isShipbound") : depths[1]),{"name":"if","hash":{},"fn":container.program(50, data, 0, blockParams, depths),"inverse":container.program(52, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":178,"column":127},"end":{"line":178,"column":166}}})) != null ? stack1 : "")
    + "\" class=\"js-train-upgrade-radio\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isSelectet") : depth0),{"name":"if","hash":{},"fn":container.program(56, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":178,"column":199},"end":{"line":178,"column":233}}})) != null ? stack1 : "")
    + ">\n                            <label for=\"train-upgrade-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":179,"column":54},"end":{"line":179,"column":60}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"isShipbound") : depths[1]),{"name":"if","hash":{},"fn":container.program(50, data, 0, blockParams, depths),"inverse":container.program(52, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":179,"column":60},"end":{"line":179,"column":99}}})) != null ? stack1 : "")
    + "\">\n                                <span class=\"bold\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.travel-flight-option.noTrain",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":180,"column":51},"end":{"line":180,"column":112}}})) != null ? stack1 : "")
    + "</span>\n                            </label>\n                        </div>\n";
},"60":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"trainToPlaneDefaultClassIncluded") : depth0),{"name":"if","hash":{},"fn":container.program(61, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":186,"column":16},"end":{"line":188,"column":23}}})) != null ? stack1 : "");
},"61":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <h2 class=\"train-upgrade-headline hint help-hint js-overlay-flight-train\"><a>"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"tpl.item.travel-flight-option.trainToPlaneDefaultClassIncluded",{"name":"formatMessage","hash":{"trainToPlaneDefaultClassIncluded":(depth0 != null ? lookupProperty(depth0,"trainToPlaneDefaultClassIncluded") : depth0)},"data":data,"loc":{"start":{"line":187,"column":97},"end":{"line":187,"column":249}}})) != null ? stack1 : "")
    + "</a></h2>\n";
},"63":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <a class=\"button js-toggle-more-flights more-options-choice\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"showMoreFlights") : depth0),{"name":"if","hash":{},"fn":container.program(64, data, 0),"inverse":container.program(66, data, 0),"data":data,"loc":{"start":{"line":198,"column":8},"end":{"line":202,"column":15}}})) != null ? stack1 : "")
    + "    </a>\n";
},"64":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"moreFlightsButtonText") || (depth0 != null ? lookupProperty(depth0,"moreFlightsButtonText") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"moreFlightsButtonText","hash":{},"data":data,"loc":{"start":{"line":199,"column":18},"end":{"line":199,"column":45}}}) : helper))) != null ? stack1 : "")
    + "</span><span class=\"icon-small-arrow-top button-up\"></span>\n";
},"66":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"moreFlightsButtonText") || (depth0 != null ? lookupProperty(depth0,"moreFlightsButtonText") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"moreFlightsButtonText","hash":{},"data":data,"loc":{"start":{"line":201,"column":18},"end":{"line":201,"column":45}}}) : helper))) != null ? stack1 : "")
    + "</span><span class=\"icon-small-arrow-down button-down\"></span>\n";
},"68":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"selected-flight-price\">\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isRoundTrip") : depth0),{"name":"if","hash":{},"fn":container.program(69, data, 0),"inverse":container.program(72, data, 0),"data":data,"loc":{"start":{"line":208,"column":8},"end":{"line":225,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isRoundTrip") : depth0),{"name":"if","hash":{},"fn":container.program(80, data, 0),"inverse":container.program(83, data, 0),"data":data,"loc":{"start":{"line":227,"column":4},"end":{"line":233,"column":11}}})) != null ? stack1 : "");
},"69":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isShipbound") : depth0),{"name":"unless","hash":{},"fn":container.program(70, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":208,"column":27},"end":{"line":211,"column":19}}})) != null ? stack1 : "");
},"70":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n            <span class=\"flight-price-info\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.travel-flight-option.cabin.from-to",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":209,"column":44},"end":{"line":209,"column":111}}})) != null ? stack1 : "")
    + " </span>\n            <span>"
    + container.escapeExpression((lookupProperty(helpers,"formatPrice")||(depth0 && lookupProperty(depth0,"formatPrice"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"price") : depth0),{"name":"formatPrice","hash":{},"data":data,"loc":{"start":{"line":210,"column":18},"end":{"line":210,"column":39}}}))
    + "</span>\n        ";
},"72":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isShipbound") : depth0),{"name":"if","hash":{},"fn":container.program(73, data, 0),"inverse":container.program(75, data, 0),"data":data,"loc":{"start":{"line":212,"column":12},"end":{"line":224,"column":19}}})) != null ? stack1 : "");
},"73":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"flight-price-info\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.travel-flight-option.cabin.to",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":213,"column":48},"end":{"line":213,"column":110}}})) != null ? stack1 : "")
    + " </span>\n                <span>"
    + container.escapeExpression((lookupProperty(helpers,"formatPrice")||(depth0 && lookupProperty(depth0,"formatPrice"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"price") : depth0),{"name":"formatPrice","hash":{},"data":data,"loc":{"start":{"line":214,"column":22},"end":{"line":214,"column":43}}}))
    + "</span>\n";
},"75":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"flight-price-info\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"shipBoundTransportTypeIsFlight") : depth0),{"name":"if","hash":{},"fn":container.program(76, data, 0),"inverse":container.program(78, data, 0),"data":data,"loc":{"start":{"line":217,"column":20},"end":{"line":221,"column":27}}})) != null ? stack1 : "")
    + "                </span>\n                <span>"
    + container.escapeExpression((lookupProperty(helpers,"formatPrice")||(depth0 && lookupProperty(depth0,"formatPrice"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"price") : depth0),{"name":"formatPrice","hash":{},"data":data,"loc":{"start":{"line":223,"column":22},"end":{"line":223,"column":43}}}))
    + "</span>\n";
},"76":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"tpl.item.travel-flight-option.cabin.from-to",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":218,"column":24},"end":{"line":218,"column":91}}})) != null ? stack1 : "")
    + "\n";
},"78":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"tpl.item.travel-flight-option.cabin.from",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":220,"column":24},"end":{"line":220,"column":88}}})) != null ? stack1 : "")
    + "\n";
},"80":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isShipbound") : depth0),{"name":"unless","hash":{},"fn":container.program(81, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":228,"column":8},"end":{"line":230,"column":19}}})) != null ? stack1 : "");
},"81":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"price-per-person\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"tpl.item.travel-flight-option.pp",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":229,"column":42},"end":{"line":229,"column":98}}})) != null ? stack1 : "")
    + "</div>\n";
},"83":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"price-per-person\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"tpl.item.travel-flight-option.pp",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":232,"column":38},"end":{"line":232,"column":94}}})) != null ? stack1 : "")
    + "</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"flight-option "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":26},"end":{"line":1,"column":60}}})) != null ? stack1 : "")
    + "\">\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":10,"column":11}}})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":4},"end":{"line":19,"column":11}}})) != null ? stack1 : "")
    + "\n    <div class=\"info\">\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"departureAirlineLogo") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.program(12, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":23,"column":8},"end":{"line":30,"column":15}}})) != null ? stack1 : "")
    + "\n        <div class=\"flight-details\">\n\n            <div class=\"flight-detail flight-detail-departure\">\n                <div class=\"bullet\"></div>\n\n                <span class=\"icon icon-icon-plane-starting\"></span>\n\n                <div>\n                    <span class=\"bold\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"departureTime") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.program(17, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":41,"column":20},"end":{"line":45,"column":27}}})) != null ? stack1 : "")
    + "                        "
    + alias3((lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"general.clock",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":46,"column":24},"end":{"line":46,"column":59}}}))
    + " |\n                     </span>\n                    "
    + alias3((lookupProperty(helpers,"formatDate")||(depth0 && lookupProperty(depth0,"formatDate"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"departureDate") : depth0),"longDate",{"name":"formatDate","hash":{},"data":data,"loc":{"start":{"line":48,"column":20},"end":{"line":48,"column":59}}}))
    + "\n                    <br>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"departureAirport") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":20},"end":{"line":52,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"layoverStoppsAirports") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":12},"end":{"line":62,"column":19}}})) != null ? stack1 : "")
    + "\n            <div class=\"flight-detail flight-detail-arrival\">\n                <div class=\"bullet\"></div>\n\n                <span class=\"icon icon-plane-landing\"></span>\n\n                <div>\n                    <span class=\"bold\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"arrivalTime") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0, blockParams, depths),"inverse":container.program(17, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":71,"column":20},"end":{"line":75,"column":27}}})) != null ? stack1 : "")
    + "                        "
    + alias3((lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"general.clock",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":76,"column":24},"end":{"line":76,"column":59}}}))
    + " |\n                    </span>\n                    "
    + alias3((lookupProperty(helpers,"formatDate")||(depth0 && lookupProperty(depth0,"formatDate"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"arrivalDate") : depth0),"longDate",{"name":"formatDate","hash":{},"data":data,"loc":{"start":{"line":78,"column":20},"end":{"line":78,"column":57}}}))
    + "<br>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"arrivalAirport") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":80,"column":20},"end":{"line":82,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n        </div>\n\n        <div class=\"flight-class\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"soleAvailableClass") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0, blockParams, depths),"inverse":container.program(29, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":88,"column":12},"end":{"line":98,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPlaceholderFlight") : depth0),{"name":"unless","hash":{},"fn":container.program(33, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":100,"column":12},"end":{"line":104,"column":23}}})) != null ? stack1 : "")
    + "        </div>\n\n    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"unless","hash":{},"fn":container.program(35, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":109,"column":4},"end":{"line":128,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(37, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":130,"column":4},"end":{"line":192,"column":11}}})) != null ? stack1 : "")
    + "\n</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasMoreFlights") : depth0),{"name":"if","hash":{},"fn":container.program(63, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":196,"column":0},"end":{"line":204,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(68, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":206,"column":0},"end":{"line":234,"column":7}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true});